import { useState, useEffect, useContext } from 'react';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

import { AuthContext } from '../authentication/AuthProvider';

function useDevicesList(deviceType, forceRefresh=false) {
    const [devicesList, setDevicesList] = useState(null);
    const { requestHeaders } = useContext(AuthContext);

    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");

    const fetchDevicesList = async (deviceType='', forceRefresh=false) => {
        const cachedData = JSON.parse(sessionStorage.getItem(`devices-${deviceType}`));
        if(deviceType === null || deviceType === undefined){
            deviceType = '';
        }
        console.log('fetchDevicesList - forceRefresh: ' + forceRefresh + ', deviceType: ' + deviceType + ', cachedData:', cachedData);
        if (!forceRefresh && cachedData) {
            console.info('using cached device data');
            track('fetchDevicesList - using cached device data');
            setDevicesList(cachedData);
        }
        else{
            console.log('fetching fresh device list, device type ' + deviceType);
            track('fetchDevicesList - fetching fresh device data, device type ' + deviceType);
            console.log('requestHeaders:', JSON.stringify(requestHeaders)); 
            try {
                const targetServer = process.env.REACT_APP_API_HOST;
                const api = `api/devices`;

                fetch(targetServer + api, {
                    method: 'GET',
                    headers: requestHeaders,
                }).then(response => {
                    if (response.ok) {
                        //return the response json for the next promise in the chain
                        return response.json();
                    } else {
                        throw new Error(response.status + " - " + response.statusText);
                    }
                }).then(data => {
                    setDevicesList(data);
                    sessionStorage.setItem(`devices-${deviceType}`, JSON.stringify(data));
                    console.log('storing devices list in session storage');
                    track('fetchDevicesList - storingdevice list in session storage');
                }).catch(error => {
                    console.error('Error fetching device list:', error);
                });
                } catch (error) {
                    console.error('Error fetching device list:', error);
                    track({severity: 'error', name: 'Error fetching device list: ' + error});
                };
            }
    };

    useEffect(() => {
        console.log('useDevicesList - useEffect - deviceType:', deviceType, 'forceRefresh:', forceRefresh);
        track('useDevicesList - useEffect - deviceType:', deviceType, 'forceRefresh:', forceRefresh);
        fetchDevicesList(deviceType, forceRefresh);
    }, [requestHeaders]);

    try{
        return {devicesList, fetchDevicesList};
    }
    catch (error){
        console.log('Error parsing deviceList:', error);
        return null;
    }
};

export default useDevicesList;