import { Container, Box, Typography, Link } from '@mui/material';
import version from './Version';

export default function Footer() {
    return <footer className="footer" id="footer">
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            >
            <Box >
              <Typography display='inline' className="footer-text"> גרסה {version} </Typography>
              <Link href="https://connect4u.co.il/?page_id=774" className="footer-text"> תנאי מדיניות</Link>&nbsp;
              <Link href="https://connect4u.co.il/?page_id=789" className="footer-text"> מדיניות פרטיות</Link>&nbsp;
            </Box>
            <Box>
              <Typography display='inline' className="footer-text">
                              ©  כל הזכויות שמורות ל<Link href="https://www.connect4u.co.il" target='_blank' rel="noreferrer">
                              קונקט פור יו </Link> בע"מ 
              </Typography>
            </Box>
          </Box>
        </Container>
      </footer>     
}