import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useJwt } from "react-jwt";
import { useParams } from 'react-router-dom'; 
import { AuthContext } from '../authentication/AuthProvider';
import GoogleSigninButton from '../buttons/GoogleSigninButton';
import Header from '../layout/Header';
import { Typography, Grid, Stack, Button } from '@mui/material';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {TailSpin} from "react-loader-spinner";


/*
This component is used to add authenticate a user to complete registration to a building
*/
function AddUser ()  {
  const appInsights = useAppInsightsContext();

  const { token } = useParams();
  const { decodedToken, isExpired } = useJwt(token);
  const { isAuthenticated, isLoading, requestHeaders, userId} = useContext(AuthContext);
  const [userAddFailed, setUserAddFailed] = useState(false);
  const [userAddInProgress, setUserAddInProgress] = useState(false);
  const [userAddCompleted, setUserAddCompleted] = useState(false);
  const [finalUserName, setFinalUserName] = useState(null);  
  
  const authenticationRedirect = window.location.href;

  console.log('isAuthenticated:', isAuthenticated);
  console.log('decoded token:', decodedToken);


  const handleConfirmation = () => {
    if(decodedToken.Flat==="0"){
      setFinalUserName("שומר/ת");
    }
    else {
      // Get the value from the text box
      const textBoxValue = document.querySelector('.text-box').value;
      // Call the method to set the final user name
      setFinalUserName(textBoxValue);
    }
  };

 function changeUser(){
    //obtain the url without the domain
    const url = window.location.href;
    const urlParts = url.split('/');
    const token = urlParts[urlParts.length-1];

    const newUrl = "/.auth/logout?post_logout_redirect_uri=/add-user/"+token;
    window.location.replace(newUrl);
 }
 
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Simulate a click on the confirmation button
      handleConfirmation();
    }
  };
  useEffect(() => {
    const addUser = async () => {
      setUserAddInProgress(true);
      //send put request to add user to building
      const targetServer = process.env.REACT_APP_API_HOST
      const api = `api/building/${decodedToken.Building}/addUser`;
  
      fetch(targetServer + api, {
          method: 'PUT',
          headers: requestHeaders,
          body: JSON.stringify({
            "building_id": decodedToken.Building,
            "user_token": token,
            "displayName": finalUserName, 
            "user_id": userId
          })
        }).then(response => {
        
          if (response.ok) {
            console.log('User added successfully! response:', response);
            //track({severity: 'info', name: 'User added successfully', response: response});
            setUserAddCompleted(true);
          } else {
            const errorData = response.text();
            console.error('Failed to add user! Error:', errorData);
            setUserAddFailed(true);
          }
        }).catch((error) => {
          //track({severity: 'error', name: 'Failed to add user', error: error});
            setUserAddFailed(true);
            console.error(error);
            });

      setUserAddInProgress(false);
    };

  if(decodedToken && isAuthenticated && finalUserName)
  {
    //if we have token and user is authenticated - add user to database
    console.log('token decoded and user autenticated - adding user');
    addUser();
  }

  if(decodedToken && !isExpired && !isAuthenticated){
    // if we have decoded token, it has not expired and user is not yet authenticated, show welcome message and sign-in button
    console.log('token decoded but user not authenticated');
  }
  }, [decodedToken, isExpired, isAuthenticated, userId, requestHeaders, finalUserName, token, appInsights]);

  return (
    <Fragment>
      <Header/>
      <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      {(isLoading || userAddInProgress) ?
        <div>
          <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
        </div> : 
        (!decodedToken || isExpired) ?
        <div>
        <p>קישור פג תוקף</p>
      </div>:
        <div className='d-flex flex-column'>
          <Grid container spacing={2} id="header"
              sx={{
                pt: 0,
                bgcolor: 'background.paper',
                boxShadow: 3,
                pb:2,
                maxWidth: 400,
              }}>
            <Grid item xs={2} sx={{backgroundColor: 'grey.200' }}>
              <img src='/logo.svg' alt='logo' width={60} height={60}/>
            </Grid>
            <Grid item xs={10} sx={{backgroundColor: 'grey.200' }} alignContent='center' alignItems='center'>
              <Stack orientation="vertical">
                <Typography variant="h4" sx={{ direction: 'rtl' }}>
                    {decodedToken!=null && decodedToken.BuildingName!=null?decodedToken.BuildingName:""}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
            <Typography variant="h6" component="h4" sx={{ direction: 'rtl' }}>שלום  {decodedToken!=null && decodedToken.Name!=null?decodedToken.Name:""} </Typography>
              <br />
          {decodedToken && !isAuthenticated &&
            <Stack direction="column" spacing={2}>
              <Typography>
                הנהלת הבניין מזמינה אותך להתחבר למערכת האינטרקום החכמה מבית Connect4U  
              </Typography>
              <Typography>-  על מנת לסיים את הרישום במערכת, יש להתחבר עם חשבון גוגל - </Typography>
              <Typography>{decodedToken.Username} </Typography>
                <GoogleSigninButton post_login_redirect_url={authenticationRedirect} />
              </Stack>
          }


          {decodedToken && isAuthenticated && !userAddCompleted &&  !userAddFailed && 
          <div className='user-name' style={{ direction: 'rtl' }}>
             <Typography>
              את/ה נרשמ/ת למערכת באמצעות  כתובת דוא"ל {userId}
            </Typography>
            <br/>
            {decodedToken.Flat!=="0" &&
              <Fragment>
                <Typography>
                כדי להשלים את ההרשמה, אנא הזן/י את השם הפרטי שלך
                </Typography>
                <br />
                <Typography>
                שם זה יופיע לאחר לחיצה על שם המשפחה ומספר הדירה שלך באינטרקום
                </Typography>
                <Typography>{decodedToken.Flat} </Typography>
                <br />
                  <input type="text" 
                  className="text-box"
                  style={{'text-align': 'center'}}
                  defaultValue={decodedToken.Name}
                  onKeyDown={()=>{handleKeyPress()}} />
              </Fragment>
            }
            <p>
            </p>
            <Button variant="contained" color="primary" style={{margin:"10px"}} onClick={()=>{handleConfirmation()}}>אישור</Button>
            <Button variant="outlined" style={{margin:"10px"}} onClick={()=>{changeUser()}}>החלפת משתמש</Button>
            </div>
          }

          { isAuthenticated && userAddCompleted && 
              <div>
                <Typography>
                  הרשמתך נקלטה בהצלחה
                </Typography>
                <Typography>
                  כל מה שנותר לעשות זה להוריד את האפליקציה ולהנות מהדור החדש של האינטרקום בישראל
                </Typography>
                  <a href="https://play.google.com/store/apps/details?id=com.connect4you.connect4umobile ">
                  <img src='/se-android-store.svg' alt="Google Play" style={{height: "50px",
                                                                                    width: "auto"}}></img>
                  </a>
                  <a href="https://apps.apple.com/app/connect-4u/id6464481755">
                  <img src={"/se-apple-store.svg"} alt="Apple Store" style={{height: "50px",
                                                                                    width: "auto"}}></img>
                  </a>
                  <p></p>
              </div>
          }
          {isAuthenticated && userAddFailed &&
          <div>
              <Typography>אירעה תקלה בהרשמתך לבניין - אנא נסה שנית במועד מאוחר יותר</Typography>
          </div>
          }
          </Grid>

          </Grid>
        </div>
      }
      </div>
    </Fragment>
  );
};

export default AddUser;

