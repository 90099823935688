import { useState, useEffect, useContext } from 'react';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

import { AuthContext } from '../authentication/AuthProvider';

export default function useBuildingData(buildingId, forceRefresh=false) {
    const [buildingData, setBuildingData] = useState(null);

    const { requestHeaders } = useContext(AuthContext);

    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");

    const fetchBuildingData = async (buildingId, forceRefresh=false) => {
        const cachedData = JSON.parse(sessionStorage.getItem(`building-${buildingId}`));
        console.log('useBuildingData - forceRefresh: ' + forceRefresh + ', buildingId: ' + buildingId + ', cachedData:', cachedData);
        if (!forceRefresh && cachedData) {
            console.info('using cached building data');
            track('useBuildingData - using cached building data');
            setBuildingData(cachedData);
        }
        else{
            if(buildingId === null || buildingId === undefined){
                console.log('buildingId is null, setting buildingData to null');
                track('useBuildingData - buildingId is null, setting buildingData to null');
                setBuildingData(null);
            }
            else{
                console.log('fetching fresh building data for building ' + buildingId);
                track('useBuildingData - fetching fresh building data for building ' + buildingId);
                try {
                    const targetServer = process.env.REACT_APP_API_HOST;
                    const api = `api/building/${buildingId}`;

                    fetch(targetServer + api, {
                        method: 'GET',
                        headers: requestHeaders
                    }).then(response => {
                        if (response.ok) {
                            //return the response json for the next promise in the chain
                            return response.json();
                        } else {
                            throw new Error(response.status + " - " + response.statusText);
                        }
                    }).then(data => {
                        setBuildingData(data);
                        sessionStorage.setItem(`building-${buildingId}`, JSON.stringify(data));
                        console.log('storing building data in session storage');
                        track('useBuildingData - storing building data in session storage');
                    }).catch(error => {
                        console.error('Error fetching building data:', error);
                    });
                    } catch (error) {
                        console.error('Error fetching building data:', error);
                        track({severity: 'error', name: 'Error fetching building data: ' + error});
                    };
                }
        }
    };

    useEffect(() => {
        console.log('useBuildingData - useEffect - buildingId:', buildingId, 'forceRefresh:', forceRefresh);
        track('useBuildingData - useEffect - buildingId:', buildingId, 'forceRefresh:', forceRefresh);
        fetchBuildingData(buildingId, forceRefresh);
    }, []);

    try{
        return {buildingData, fetchBuildingData};
    }
    catch (error){
        console.log('Error parsing building data:', error);
        return null;
    }
};