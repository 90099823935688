import React, { useState } from 'react';
import { TextField } from '@mui/material';

export default function  IpAddressInput (props) {
  const [ipAddress, setIPAddress] = useState(props.value);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const ip = e.target.value;
    const isValid = validateIpAddress(ip);
    setError(!isValid);
    setIPAddress(ip);
    if (isValid) {
      props.onChange(ip);
    }
  };

  const validateIpAddress = (ip) => {
    const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  };

  return (
    <TextField
      required
      autoFocus
      value={ipAddress}
      onChange={handleChange}
      error={error}
      helperText={error ? 'Invalid IP address' : ''}
      label="IP Address"
      variant="standard"
      fullWidth
    />
  );
}

