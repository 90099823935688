import { useState, useEffect, useContext } from 'react';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { AuthContext } from '../authentication/AuthProvider';

function useBuildingList(forceRefresh = false) {
    const [buildingsList, setBuildingsList] = useState(() => {
        const cachedData = sessionStorage.getItem('buildingsList');
        return cachedData ? JSON.parse(cachedData) : [];
      });    

    const { requestHeaders } = useContext(AuthContext);

    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");


    const fetchBuildingsList = async (forceRefresh=false) => {
        //try to load cached data from session storage (react does not persist state between page loads)
        console.log('requestHeaders: ' + JSON.stringify(requestHeaders));

        //if forceRefresh is false and there is cached data, use it, so nothing to do
        if (!forceRefresh && sessionStorage.getItem('buildingsList')) {
            console.log('using cached buildings list');
            track({severity: 'info', name: 'using cached buildings list'});
            return;
        }
        console.log('fetching fresh buildings list');
        track('fetching fresh buildings list');
        try {
            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/GetBuildings`;

            fetch(targetServer + api, {
                method: 'GET',
                headers: requestHeaders
            }).then(response => {
                if (response.ok) {
                    console.info('fetch buildings list - response ok');
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            }).then(data => {
                setBuildingsList(data);
                console.log('storing buildings list in session storage');
                sessionStorage.setItem('buildingsList', JSON.stringify(data));
                }).catch(error => {
                    throw new Error(error);
                });
            }
            catch (error)
            {
                track({severity: 'error', name: 'בעיה בטעינת רשימת בניינים: ' + error});
                throw new Error('בעיה בטעינת רשימת בניינים: ' + error);
            }
    };
    

    useEffect(() => {
        fetchBuildingsList();
        }, [requestHeaders]);

    return { buildingsList, fetchBuildingsList };
};

export default useBuildingList;

