import React , {useContext, useState, useEffect,Fragment} from 'react'
import { AuthContext } from '../authentication/AuthProvider';
import {Box,Checkbox, ListItemText, List, ListItem, ListItemIcon,Divider, Container, Typography, Button, Grid} from '@mui/material';
import { Cancel,Upgrade, Fence,RestartAlt,SmartScreen, LocalPolice, Screenshot,PhoneIphone,Refresh, Link, LinkOff, ContentPasteSearchOutlined} from '@mui/icons-material';
import Header from 'components/layout/Header';
import Footer from '../layout/Footer';
import {TailSpin} from "react-loader-spinner";
import useDevicesList from '../commands/useDevicesList';
import FilterList from 'components/buttons/FilterList';
import { useNavigate } from 'react-router-dom';
import useDeviceActions from "../commands/useDeviceActions";
import Confirmation from "../inputs/Confirmation";

function Devices(){

    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const { devicesList, fetchDevicesList } = useDevicesList();
    const { restartDevice, upgradeDevice } = useDeviceActions();
    const [filteredList, setFilteredList] = useState(null);
    const [openRestartDialog, setOpenRestartDialog] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [restartDisabled, setRestartDisabled] = useState(true); //for selection
    const [upgradeDisabled, setUpgradeDisabled] = useState(true);
    const [cancelDisabled, setCancelDisabled] = useState(true);
    //for selection
    const [deviceCheckedState, setDeviceCheckedState] = useState({});    // uncehcekd, checked, completed, exists or error

    const navigate = useNavigate();

    // exectue when buildingsList is upadated (when hook completes)
    useEffect(() => {
      if (devicesList !== null && devicesList !== undefined) {
        setFilteredList(devicesList);
       }
      }, [devicesList]);

      useEffect(() => {
        const checkedItems = Object.entries(deviceCheckedState).filter(([deviceId, state]) => state === 'checked');
        if(checkedItems.length === 0){
          setRestartDisabled(true);
          setUpgradeDisabled(true);
          setCancelDisabled(true);
        }
        else{
          setCancelDisabled(false);
          //check if all checked items are connected
          const allConnected = checkedItems.every(([deviceId, state]) => {
            const device = devicesList.find(device => device.deviceid === deviceId);
            return device && device.connectionState && device.connectionState === 'Connected';
          });
          setRestartDisabled(!allConnected);
          setUpgradeDisabled(!allConnected);
          }
        
          }, [deviceCheckedState]);
  
      const dataFiltered = (filtered) => {
        setFilteredList(filtered);
     }

      const refreshList = () => {
        fetchDevicesList(null,true); // Force refresh
      };
  
      const handleDeviceClick = (e, buildingId, flatId, deviceId) => {
        navigate(`/building/`+buildingId + "/"+flatId+"/" + deviceId);
    };   

    
    const handleDeviceCheckboxChange = (deviceId, status) => {
      setDeviceCheckedState(prevState => {
          const currentState = prevState[deviceId];
  
          // If already completed, do not allow any change to state
          if (currentState === 'completed') {
              return prevState;
          }
  
          // Update the state based on the event
          return {
              ...prevState,
              [deviceId]: status
          };
      });
  };
  const cancelRestart = () => {
    setOpenRestartDialog(false);
  };
  const confirmRestart = () => {
    setOpenRestartDialog(true);
   };

  const handleRestart = async () => {
    setOpenRestartDialog(false);
    const checkedDevices = Object.entries(deviceCheckedState).filter(([key, value]) => value === 'checked').map(([key]) => key);
    checkedDevices.forEach(deviceid => {
        const device = filteredList.find(device => device.deviceid === deviceid);
        if (device) {
                restartDevice(device.buildingid, device.deviceid, device.devicetype)
                .then(response => {
                    console.log("device restarted: " + device.deviceid);
                    })
                .catch(error => {
                    console.error("error importing tenant: " + error);
                });
            }
        //remove the device from the list
        setDeviceCheckedState(Object.entries(deviceCheckedState).filter(device => device.deviceid !== deviceid));
        });
  }
  const cancelUpgrade = () => {
    setOpenUpgradeDialog(false);
  }
  const confirmUpgrade = () => {
    setOpenUpgradeDialog(true);
  }
  const handleUpgrade = async () => {
    setOpenUpgradeDialog(false);
    const checkedDevices = Object.entries(deviceCheckedState).filter(([key, value]) => value === 'checked').map(([key]) => key);
    console.log(checkedDevices);

    checkedDevices.forEach(deviceid => {
        const device = filteredList.find(device => device.deviceid === deviceid);
        if (device) {
            console.log("device upgrading: " + device.deviceid);
            upgradeDevice(device.buildingid, device.flatid, device.deviceid);
        }
        //remove the device from the list
        setDeviceCheckedState(Object.entries(deviceCheckedState).filter(device => device.deviceid !== deviceid));
    });
  }
  



    return(
      <Fragment> 
      <Header/>
      <Container maxWidth="md" sx={{pt:2}}>
    
        {filteredList ? 
        <div className='d-flex flex-column'>
          <Fragment>
          <List 
        sx={{
          pt: 0,
          bgcolor: 'background.paper',
          boxShadow: 3,
          overflowY: 'auto',
          
        }}>
          <ListItem sx={{backgroundColor:"grey.200"}} key="header1">
          <div className="d-flex flex-row justify-content-center align-items-center w-100">
            <Typography variant='h4' color="GrayText">רשימת מכשירים</Typography>
          </div>
          </ListItem>
          <ListItem alignItems='flex-start' key="header2">
            <Grid container spacing={2} justifyContent="space-between" alignItems='center'>
              <Grid item xs={6}> 
                <FilterList data={devicesList} onFilter={dataFiltered} fields="['title', 'deviceid', 'devicetype','ACSID','displayName','connectionState','appVersion']"/>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button variant="contained" size="medium" startIcon={<Refresh />} onClick={refreshList} >&nbsp; רענון רשימה &nbsp;</Button>
              </Grid>
            </Grid>
            </ListItem>
          <ListItem key="header3">
            <ListItemText sx={{textAlign:"start", fontWeight: 'bold',flex: '1 1 5%'}}><Typography  fontWeight="bold" >סוג </Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}} ><Typography  fontWeight="bold" > בניין</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}}><Typography fontWeight="bold" >דירה</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 25%'}}><Typography fontWeight="bold" >שם מכשיר</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 10%'}}><Typography fontWeight="bold" >גרסה</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold',flex: '1 1 10%'}}><Typography fontWeight="bold" >סטטוס</Typography></ListItemText>
          </ListItem>
          </List>
        <List 
 sx={{
  pt: 0,
  bgcolor: 'background.paper',
  boxShadow: 3,
  mb:1,
  maxHeight: {
    xs:'500px',
    sm: '800px'
  },
  overflowY: 'auto'

}}  >
          {filteredList && filteredList.map((device) => (
            <div>
              <Divider variant="fullwidth" component="li" />
              <ListItem  key={device.deviceid} onClick={(e)=>{handleDeviceClick(e,device.buildingid, device.flatid, device.deviceid)}}
               sx={{
                '&:hover': {
                  backgroundColor: 'grey.200' // change this to your preferred color
                },
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                textAlign: 'right',
              }}>
                <Checkbox 
                  checked={deviceCheckedState[device.deviceid] === 'checked' || false}
                  onChange={(event) => handleDeviceCheckboxChange(device.deviceid,event.target.checked?'checked':'unchecked')}
                  onClick={(event) => event.stopPropagation()}
                  />    
                <ListItemIcon>
                        {
                            device.devicetype === 'monitor' ? <SmartScreen/> :
                            device.devicetype === 'guard' ? <LocalPolice/> :
                            device.devicetype === 'base' ? <Screenshot/> :
                            device.devicetype === 'phone' ? <PhoneIphone/> :
                            device.devicetype === 'gate' ? <Fence/> :
                            null
                        }
                </ListItemIcon>
                <ListItemText  primary={device.title}   sx={{ flex: '1 1 20%' }} />
                <ListItemText secondary={device.flatname}  sx={{ flex: '1 1 20%' }}  />
                <ListItemText secondary={device.displayName}   sx={{ flex: '1 1 20%' }} />
                <ListItemText  sx={{ flex: '1 1 10%' }} >
                  {device.appVersion}
                </ListItemText>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {
                    device.connectionState === undefined ? '' :
                    device.connectionState === 'Connected' ? <Link/> : <LinkOff/>} 
                </ListItemIcon>
              </ListItem>
            </div>
    
              ))}
        </List>
        <Box display="flex" justifyContent="right" alignItems="center">
      <Button variant="contained" size="medium" startIcon={<RestartAlt />} sx={{ width: '120px', margin: '10px' }} onClick={confirmRestart} disabled={restartDisabled}>
        &nbsp; אתחול &nbsp;
      </Button>
      <Button variant="contained" size="medium" startIcon={<Upgrade />} sx={{ width: '120px', margin: '10px' }} onClick={confirmUpgrade} disabled={upgradeDisabled}>
        &nbsp; שדרוג &nbsp;
      </Button>
      <Button variant="contained" size="medium" startIcon={<Cancel />} sx={{ width: '120px', margin: '10px' }} onClick={()=>{setDeviceCheckedState({})}} disabled={cancelDisabled}>
        &nbsp; ביטול &nbsp;
      </Button>
    </Box>
  {
          openRestartDialog &&
            <Confirmation open={openRestartDialog} title={"אתחול מכשיר"} content={ "אם לאתחל את המכשירים ?"} onConfirm={handleRestart} onCancel={cancelRestart}/>
        }
        {
          openUpgradeDialog &&
            <Confirmation open={openUpgradeDialog} title={"שדרוג מכשיר"} content={"האם לשדרג את המכשירים ?"} onConfirm={handleUpgrade} onCancel={cancelUpgrade}/>
        }
        </Fragment>
        <Footer/>
        </div>
          : (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
            <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
          </div>
            )}
        </Container>
        </Fragment>
      );

}

export default Devices;