import { Stack, TextField } from "@mui/material";
import React , { useEffect} from 'react'
import { Search} from '@mui/icons-material';

export default function FilterList(properties){

  const data = properties.data;
  

    const handleSearch = (e) => {
        const filter = e.target.value;
        if (!filter || filter === '') {
          properties.onFilter(data);
        }
        const regex = new RegExp(filter, 'gi');
        let result = [];
        try{
        data.forEach((item) => {
          item.searchString = flattenObject(item,properties.fields);
          if(item.searchString.match(regex)){
            result.push(item);
          }
        });
        properties.onFilter(result);
      }catch(e){
        console.log('error in filter: ' + e);
        properties.onFilter(data);
      }
      };

      function flattenObject(obj, fields) {
        let result = '';
      
        for (const prop in obj) {
          if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            //if property is an object, recursively flatten it, then append result
            result += flattenObject(obj[prop],fields);
          } else {
            //append the property to result if it's not an object and is included in fields
            if (fields.includes(prop)){
              result += obj[prop];
            }
          }
        }
        return result.trim();
      }

    return(
      <Stack direction="row" alignItems="center"  justifyContent="center">
            <Search />
        <TextField
              placeholder='חיפוש'
              name='buildings'
              onChange={handleSearch}
              sx={{width: '100px',
                '& .MuiInputBase-root': {
                  height: '30px', // Set the height here
                },
                '& .MuiOutlinedInput-input': {
                  padding: '0 14px', // Adjust padding to fit the new height
                },}}
            />
        </Stack>
    );
}