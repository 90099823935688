import { useContext } from 'react';
import { AuthContext } from '../authentication/AuthProvider';
import { toast } from 'react-toastify';

function useDeviceActions(){

    const {requestHeaders} = useContext(AuthContext);

    const fetchDeviceStatus = async (deviceId, deviceType) => {
        console.log('fetching device status for device id:', deviceId, 'device type:', deviceType);
        if (deviceType === 'base' || deviceType === 'monitor' || deviceType === 'guard' || deviceType === 'gate') {
            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/devices/${deviceId}?devicetype=${deviceType}`;
            try {
                const response = await fetch(targetServer + api, {
                    method: 'GET',
                    headers: requestHeaders
                });
                const data = await response.json();
                return {status:data.connected, version:data.appVersion};
            } catch (error) {
                console.error('Error fetching device status: ' + error);
            }
        }
        else{
            console.error('invalid device type');
        }
    }

    const restartDevice = async (buildingId, deviceId,deviceType) => {
        console.log('rebooting device:', deviceId);
        const targetServer = process.env.REACT_APP_API_HOST;
        const api = 'api/callPanel';
        const request_body =  {
            "buildingId":buildingId,
            "deviceId":deviceId,
            "deviceType":deviceType,
            "action":"reboot"}
        const request = {
          method: 'POST',
          deviceid: deviceId,
          headers: requestHeaders,
          action: 'reboot',
          body: JSON.stringify(request_body)
        }
  
        try{
          const response = await fetch(targetServer + api , request);
          const data = await response.json();
          
          if(response.status !== 200){
            console.error('Error rebooting device:', data.error);
            return false;
          }
          console.log('Reboot device sucessful! response:', data);
          return true;
        }
        catch (error) {
          console.error(error);
          return false;
        };
      };


      const upgradeDevice = async (buildingId, flatId, deviceId) => { 
        const targetServer = process.env.REACT_APP_API_HOST
        const api = 'api/building/'+buildingId+'/'+flatId+'/'+deviceId+'/upgrade/';
        const request_body =  {};
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(request_body)
          });
          const data = await response.json();
          if(response.status !== 200){
            console.error('Error updating building:', data.error);
            return false;
          }
          console.log(response.status);
          console.log('Update building sucessful! response:', data);
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      };

    return {fetchDeviceStatus, restartDevice, upgradeDevice};
}

export default useDeviceActions;