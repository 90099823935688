import React , { useState, useEffect, useContext, Fragment} from 'react';
import { Dialog, DialogTitle, DialogActions, TextField, DialogContent, Button,  Stack, FormLabel, FormControlLabel, Radio, RadioGroup, Checkbox} from '@mui/material';
import MacAddressInput from '../inputs/MacAddressInput';
import { toast } from 'react-toastify';
import { AuthContext, isAdmin } from '../authentication/AuthProvider';
import { TailSpin } from 'react-loader-spinner';
import IpAddressInput from 'components/inputs/IPAddressInput';

export default function AddDevice({show, buildingId, flatId, onAddDevice, onComplete, deviceType}) {
  const {requestHeaders, isAdmin} = useContext(AuthContext);

  const [deviceId, setDeviceId] = useState('');
  const [doorCode, setDoorCode] = useState('');
  const [doorCodeError, setDoorCodeError] = useState(false); 
  const [deviceName, setDeviceName] = useState('');
  const [hostName, setHostName] = useState('');
  const [unitType, setUnitType] = useState(deviceType); 
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  
  const [allowExistingDevice, setAllowExistingDevice] = useState(false); //override check for existing MAC address in backend
  
  const [open, setOpen] = React.useState(false);
  const [macError, setMacError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const targetServer = process.env.REACT_APP_API_HOST;
  
    useEffect(() => {
        setOpen(show);
        setUnitType(deviceType);
    }, [show]);

    const handleClose = () => {
        onComplete();
      };

    const handleAdd = () => {
      switch(unitType){
        case 'phone':
        case 'guard':
          if(emailError || phoneError || deviceName.trim() === '') {
            toast.error('נא למלא את כל השדות הנדרשים');
            return;
          }
          else{
            addPhone(deviceName, email, phone, deviceType);
          }
          break;
        case 'monitor':
          if(deviceName.trim() === '' || macError) {
            toast.error('נא למלא את כל השדות הנדרשים');
            return;
          }
          else{
            addDevice(deviceId, deviceName, '', unitType);
          }
          break;
        case 'gate':
          addDevice(deviceId, deviceName, '', unitType, allowExistingDevice, hostName);
          break;
        case 'base':
          if(deviceName.trim() === '' || (flatId==="0" && doorCode.trim()==='')) {
            toast.error('נא למלא את כל השדות הנדרשים');
            return;
          }
          else{
            addDevice(deviceId, deviceName, doorCode, unitType, allowExistingDevice);
          }
          break;
        }
      };

      const addPhone = async (name, email, phone, deviceType='phone') => {
        setShowSpinner(true);
        const tenant = {
          name: name,
          email: email,
          phone: phone,
          devicetype: deviceType, 
          allowExistingDevice: allowExistingDevice
      };
        const api = `api/building/${buildingId}/${flatId}/addtenant`;
        console.log('Add tenant called!', api, tenant);
        const deviceTypeName = deviceType==='phone'?'דייר':'שומר';
        try {
          const response = await fetch(targetServer + api, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(tenant),
          });
          
          if (response.ok) {
              const data = await response.json();
              console.log('Tenant added successfully! Response:', data);
              toast.success('הוספת ' + deviceTypeName + ' הושלמה בהצלחה ');
              onAddDevice(); //callback to caller refresh device list

          } else {
              const errorData = await response.text();
              console.error('Failed to add tenant! Error:', errorData);
              toast.error('שגיאה בהוספת ' + deviceTypeName + errorData);
          }
        } catch (error) {
          toast.error('Unexpected error', error);
          console.error(error);
        }
        setShowSpinner(false);
    };
  
      const addDevice =  async (deviceid, name, doorcode, deviceType, allowExistingDevice, hostname) => {
        const api = `api/building/${buildingId}/${flatId}/AddDevice`;
        console.log('Add device called!', api, deviceid);
  
        const device = {
          mac: deviceid,
          name: name,
          doorcode: doorcode,
          deviceType: deviceType, 
          allowExistingDevice: allowExistingDevice,
          hostname: hostname
        }
        try {
            setShowSpinner(true);
            const response = await fetch(targetServer + api, {
              method: 'POST',
              headers: requestHeaders,
              body: JSON.stringify(device),
            });
            if (response.ok) {
                const data = await response.json();
                console.log('device added successfully! Response:', data);
                toast.success('מכשיר הוסף בהצלחה');
                onAddDevice(); //callback to caller refresh device list
                setShowSpinner(false);
                handleClose();
                } else {
                const errorData = await response.text();
                console.error('Failed to add device! Error:', errorData);
                toast.error(`בעיה בהוספת מכשיר ${errorData}`);
                setShowSpinner(false);
            }
          } catch (error) {
            toast.error(`בעיה בהוספת מכשיר: ${error.message}`);
            console.error(error);
            setShowSpinner(false);

          }
      };


const handleDoorCodeChange = (event) => {
  const { value } = event.target;
  //check if doorcode is 4 digits
  const doorcodeRegex = /^\d{4}$/;
  if(doorcodeRegex.test(value) || value === ''){
    setDoorCode(value);
    setDoorCodeError(false);
  }
  else{
    setDoorCodeError(true);
  }
};

const emailUpdated = (email) => { 
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if(emailRegex.test(email) || email === ''){
    setEmailError(false);
    setEmail(email);
  }
  else{
    setEmailError(true);
  }
};

const phoneUpdated = (phone) => {
  //check if phone is 10-15 digits and starts with either a 0 or a +
  const phoneRegex = /^[0+]\d{9,14}$/;
  if(phoneRegex.test(phone) || phone === ''){
    if(phone.startsWith('0')){
      phone = `+972${phone.substring(1)}`; //convert to international format
    }
    setPhoneError(false);
    setPhone(phone);
  }
  else{
    setPhoneError(true);
  }
};
      
    return(
    <Dialog open={open} onClose={handleClose} >
      {showSpinner ? <div style={{padding:'100px'}}><TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} /></div> :
      <Fragment>
    <DialogTitle>הוספת {deviceType==='phone'? 'טלפון' : deviceType==='guard'?'שומר':'מכשיר'}</DialogTitle>
    <DialogContent>
    <TextField
        required
        margin="dense"
        id="name"
        name="name"
        label={`שם ${deviceType!=="phone" && deviceType!="guard"?"מכשיר":""}`}
        type="text"
        onChange={(event) => setDeviceName(event.target.value)}
        fullWidth
        variant="standard"/>
      {deviceType==='phone' || deviceType=='guard'? 
        <Fragment>
        <TextField
        required
        margin="dense"
        id="email"
        name="email"
        label="דואר אלקטרוני"
        type="text"
        onChange={(event) => emailUpdated(event.target.value)}
        helperText={emailError?'כתובת מייל לא תקינה':''}
        error={emailError}
        fullWidth
        variant="standard"/>
                <TextField
        required
        margin="dense"
        id="phone"
        name="phone"
        label="מספר טלפון"
        type="text"
        onChange={(event) => phoneUpdated(event.target.value)}
        helperText={phoneError?'מספר טלפון לא תקין':''}
        error={phoneError}
        fullWidth
        variant="standard"/>
        </Fragment>
        :
      <Fragment>
        {(flatId===0 || flatId==="0") &&
        <Stack direction="row" spacing={2} alignItems="center">
          <FormLabel id="demo-radio-buttons-group-label">סוג מכשיר</FormLabel>
          <RadioGroup row aria-label="unitType" name="unitType" value={unitType} onChange={(e) => setUnitType(e.target.value)}>
            <FormControlLabel value="base" control={<Radio />} label="כניסה" />
            <FormControlLabel value="gate" control={<Radio />} label="שער" />
          </RadioGroup>
        </Stack>
      }
      <Stack direction="column" spacing={2} alignItems="center">
        {unitType==='gate'? 
        <Fragment>
            <IpAddressInput value="" onChange={(result)=>setHostName(result)} edit={true} required/>
            <TextField
        required
        margin="dense"
        id="serialNumber"
        name="serialNumber"
        label="מספר סידורי"
        type="text"
        onChange={(event) => setDeviceId(event.target.value)}
        fullWidth
        variant="standard"/>
        </Fragment>
            :
            <MacAddressInput value={deviceId} onChange={(result)=>setDeviceId(result)} edit={true}/>
        }
        {
          (flatId===0 || flatId==="0") && (unitType==="base") && 
          <Stack direction="column" spacing={2} alignItems="center">
            <TextField
              required
              margin="dense"
              id="doorcode"
              name="doorcode"
              label="קוד פתיחה"
              type="text"
              onChange={handleDoorCodeChange}
              error={doorCodeError}
              helperText={doorCodeError?'קוד דלת נדרש להיות 4 ספרות':''}
              fullWidth
              variant="standard"
              inputProps={{
                maxLength: 4,
              }}/>
            </Stack>
          }
      </Stack>
      </Fragment>
      }
      {unitType!=="phone" &&
      <FormControlLabel
        control={
          <Checkbox
            checked={allowExistingDevice}
            onChange={(e) => setAllowExistingDevice(e.target.checked)}
          />
        }
        label="אפשר הוספת מכשיר קיים"
      />
    }
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>ביטול</Button>
      <Button onClick={handleAdd}>הוספה</Button>
    </DialogActions>
    </Fragment>
}
  </Dialog>);

}